import * as actionTypes from "./actionTypes"
import MainApi from "../utils/api/main"

export function set_context_object(context) {
  return async (dispatch: DispatchType) => {
    const action: StoreAction = {
      type: actionTypes.SET_CONTEXTOBJECT,
      result: context
    }
    return dispatch(action)
  }
}

export function fetch_activities(skip, limit) {
  return async (dispatch: DispatchType) => {
    const mainApi = MainApi.getInstance()
    const response = await mainApi.fetchActivityMessages({queued: 0}, skip, limit)
    if(response.success === true) {
      const action: StoreAction = {
        type: actionTypes.FETCH_ACTIVITIES,
        result: response
      }
      return dispatch(action)
    }
  }
}

export function fetch_activities_queued(skip, limit) {
  return async (dispatch: DispatchType) => {
    const mainApi = MainApi.getInstance()
    const response = await mainApi.fetchActivityMessages({queued: 1}, skip, limit)
    if(response.success === true) {
      const action: StoreAction = {
        type: actionTypes.FETCH_ACTIVITIES_QUEUED,
        result: response
      }
      return dispatch(action)
    }
  }
}