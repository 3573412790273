import * as actionTypes from "./actionTypes"

const initialState: StoreState = {
  context: null,
  activities: [],
  activitiesQueued: [],
  activitiesCount: 0,
  activitiesQueuedCount: 0
}

const reducer = (
  state: StoreState = initialState,
  action: StoreAction
): StoreState => {
  switch (action.type) {
    case actionTypes.SET_CONTEXTOBJECT:
      return {
        ...state,
        context: action.result
      }
    case actionTypes.FETCH_ACTIVITIES:
      return {
        ...state,
        activities: action.result.activity,
        activitiesCount: action.result.rows
      }
    case actionTypes.FETCH_ACTIVITIES_QUEUED:
      return {
        ...state,
        activitiesQueued : action.result.activity,
        activitiesQueuedCount: action.result.rows
      }
  }
  return state
}

export default reducer