
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Buffer } from 'buffer'

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

export default abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });

    this._initializeResponseInterceptor();
    this._initializeRequestInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError,
    );
  };

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleError,
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    if (config.headers === undefined) {
      config.headers = {};
    }
    config.headers['Authorization'] = 'Basic ' + Buffer.from(window.__RUNTIME_CONFIG__.REACT_APP_API_USER + ":" + window.__RUNTIME_CONFIG__.REACT_APP_API_PASSWORD).toString("base64")
    return config;
  };

  private _handleResponse = ({ data }: AxiosResponse) => data;

  protected _handleError = (error: any) => Promise.reject(error);
}