import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, teamsTheme } from '@fluentui/react-northstar';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import * as microsoftTeams from "@microsoft/teams-js";
import App from './App';
import reducer from './store/reducer';
import reportWebVitals from './reportWebVitals';
import { createStore, applyMiddleware, Store } from 'redux';
import { Provider as Redux } from 'react-redux';
import thunk from 'redux-thunk';

initializeIcons()
microsoftTeams.initialize();

const store: Store<StoreState, StoreAction> & {
  dispatch: DispatchType
} = createStore(reducer, applyMiddleware(thunk))

ReactDOM.render(
  <React.StrictMode>
    <Redux store={store}>
      <I18nextProvider i18n={i18n}>
        <Provider theme={teamsTheme}>
          <App />
        </Provider>
      </I18nextProvider>
    </Redux>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();