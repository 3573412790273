import React, { useEffect, useState } from 'react'
import { Flex, Grid, Button, Header, Segment, Form, FormButton, FormInput, Dropdown, Image, Avatar, Checkbox, RadioGroup, Loader } from "@fluentui/react-northstar"
import { AddIcon, PersonIcon } from '@fluentui/react-icons-northstar'
import { Icon } from '@fluentui/react/lib/Icon'
import { useTranslation } from "react-i18next"
import { useDialog } from "../../hooks/useDialog"
import { CustomDialog, ActivityTable, ControlledEditor } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment"
import MainApi from '../../utils/api/main'
import { ActivityMessage } from "../../utils/api/types"
import { fetch_activities, fetch_activities_queued } from '../../store/actionCreators'

import { StoreState } from '../../type'

const fetchLimit = 5

function createRequestFilter(filter) {
  var result = ""
  if (filter) {
    for (const key in filter) {
      if (filter[key])
        result += `&filter=OR(${key}:${filter[key]})`
    }
  }
  return result
}

export const Dashboard: React.FunctionComponent = () => {
  const { t } = useTranslation()
  const mainApi = MainApi.getInstance()
  const dispatch = useDispatch()
  const context = useSelector((state: StoreState) => state.context)
  const activities = useSelector((state: StoreState) => state.activities)
  const activitiesCount = useSelector((state: StoreState) => state.activitiesCount)
  const [paginationActivities, setPaginationActivities] = useState(1)
  const activitiesQueued = useSelector((state: StoreState) => state.activitiesQueued)
  const activitiesQueuedCount = useSelector((state: StoreState) => state.activitiesQueuedCount)
  const [paginationActivitiesQueued, setPaginationActivitiesQueued] = useState(1)
  const [dialogOpen, setDialogOpen] = useDialog()
  const [previewOpen, setPreviewOpen] = useDialog()
  const [currentStep, setCurrentStep] = useState(0)
  const [isScheduled, setIsScheduled] = useState(false)
  const [allowedGroupsToSend, setAllowedGroupsToSend] = useState([])
  const [allowedUsersToSend, setAllowedUsersToSend] = useState([])
  const [typingTimeout, setTypingTimeout] = useState()
  const [typingLoader, setTypingLoader] = useState(false)

  const [activity, setActivity] = useState({
    title: null,
    imageUrl: '',
    text: null,
    actions: [] as any[]
  })

  const [data, setData] = useState({
    _id: null,
    receiver_type: 'everyone',
    receiver: null,
    draft: 1,
    scheduledDate: null,
    scheduledTime: null
  })

  useEffect(() => {
    function startTimer() {
      setTimeout(()=> {
        dispatch(fetch_activities(paginationActivities, fetchLimit))
        dispatch(fetch_activities_queued(paginationActivitiesQueued, fetchLimit))
        startTimer()
      }, 15000);
    }
    startTimer()
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    dispatch(fetch_activities(paginationActivities, fetchLimit))
    dispatch(fetch_activities_queued(paginationActivitiesQueued, fetchLimit))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchGroupsToSend = async () => {
      var response = await mainApi.fetchGroups("", 100)
      if (response.success === true) {
        var newArray = response.data.map((element: { displayName: any }) => {
          return element.displayName
        })
        setAllowedGroupsToSend(newArray)
      }
    }
    const fetchUsersToSend = async () => {
      var response = await mainApi.fetchUsers("", 100)
      if (response.success === true) {
        var newArray = response.data.map((element: { mail: any }) => {
          return element.mail
        })
        setAllowedUsersToSend(newArray)
      }
    }
    if (dialogOpen === true) {
      fetchGroupsToSend()
      fetchUsersToSend()
      setCurrentStep(0)
    }
    // eslint-disable-next-line
  }, [dialogOpen])
  
  const getItems = () => {
    return [
      {
        name: 'everyone',
        key: 'everyone',
        label: t("label-everyone"),
        value: 'everyone',
      },
      {
        name: 'groups',
        key: 'groups',
        label: t("label-group"),
        value: 'groups',
        children: (Component: any, { ...props }) => {
          return (
            <Flex column gap="gap.small">
              <Component {...props} />
              {data.receiver_type! === "groups" &&
                <Dropdown
                  key='groups-dropdown'
                  onFocus={() => handleOnReceiverTypeChange(null, { value: 'groups' })}
                  onChange={handleOnReceiverChange}
                  onSearchQueryChange={handleOnGroupSearchQueryChange}
                  loading={typingLoader}
                  loadingMessage={<Loader size="small" />}
                  disabled={data.receiver_type !== "groups"}
                  multiple
                  search
                  items={allowedGroupsToSend}
                  placeholder={t("placeholder-typing")}
                  noResultsMessage={t("placeholder-noResults")}
                />
              }
            </Flex>
          );
        },
      },
      {
        name: 'users',
        key: 'users',
        label: t("label-user"),
        value: 'users',
        children: (Component: any, { ...props }) => {
          return (
            <Flex column gap="gap.small">
              <Component {...props} />
              {data.receiver_type! === "users" &&
                <Dropdown
                  key='groups-dropdown'
                  onFocus={() => handleOnReceiverTypeChange(null, { value: 'users' })}
                  onChange={handleOnReceiverChange}
                  onSearchQueryChange={handleOnUserSearchQueryChange}
                  loading={typingLoader}
                  loadingMessage={<Loader size="small" />}
                  disabled={data.receiver_type !== "users"}
                  multiple
                  search
                  items={allowedUsersToSend}
                  placeholder={t("placeholder-typing")}
                  noResultsMessage={t("placeholder-noResults")}
                />
              }
            </Flex>
          );
        },
      }
    ];
  }

  const handleOnGroupSearchQueryChange = (_: any, event: any) => {
    const { searchQuery } = event
    setTypingLoader(true)
    if (typingTimeout) {
      clearTimeout(typingTimeout);
      setTypingTimeout(null);
    }
    setTypingTimeout(
      setTimeout(async () => {
        const filter = createRequestFilter({ displayName: searchQuery })
        const response = await mainApi.fetchGroups(filter, 100)
        if (response.success === true) {
          var newArray = response.data.map((element: { displayName: any }) => {
            return element.displayName
          })
          setAllowedGroupsToSend(newArray)
        }
        else {
          setAllowedGroupsToSend([])
        }
        setTypingLoader(false)
      }, 1200)
    )
  }

  const handleOnUserSearchQueryChange = (_: any, event: any) => {
    const { searchQuery } = event
    setTypingLoader(true)
    if (typingTimeout) {
      clearTimeout(typingTimeout);
      setTypingTimeout(null);
    }
    setTypingTimeout(
      setTimeout(async () => {
        const filter = createRequestFilter({ mail: searchQuery, displayname: searchQuery, givenName: searchQuery, surname: searchQuery })
        const response = await mainApi.fetchUsers(filter, 100)
        if (response.success === true) {
          var newArray = response.data.map((element: { mail: any }) => {
            return element.mail
          })
          setAllowedUsersToSend(newArray)
        }
        else {
          setAllowedUsersToSend([])
        }
        setTypingLoader(false)
      }, 1200)
    )
  }

  const handleOnClickNewAnnouncement = () => {
    setIsScheduled(false)
    setActivity({
      title: null,
      imageUrl: '',
      text: null,
      actions: [] as any[]
    })
    setData({
      _id: null,
      receiver_type: 'everyone',
      receiver: null,
      draft: 1,
      scheduledDate: null,
      scheduledTime: null
    })
    setDialogOpen(true)
  }

  const handleOnFetchNextActivities = async () => {
    var skip = paginationActivities + fetchLimit
    setPaginationActivities(skip)
    dispatch(fetch_activities(skip, fetchLimit))
  }

  const handleOnFetchPrevActivities = async () => {
    var skip = paginationActivities - fetchLimit
    if (skip >= 1) {
      setPaginationActivities(skip)
      dispatch(fetch_activities(skip, fetchLimit))
    }
  }

  const handleOnFetchNextActivitiesQueued = async () => {
    var skip = paginationActivitiesQueued + fetchLimit
    setPaginationActivitiesQueued(skip)
    dispatch(fetch_activities_queued(skip, fetchLimit))
  }

  const handleOnFetchPrevActivitiesQueued = async () => {
    var skip = paginationActivitiesQueued - fetchLimit
    if (skip >= 1) {
      setPaginationActivitiesQueued(skip)
      dispatch(fetch_activities_queued(skip, fetchLimit))
    }
  }

  const handleOnSubmit = async (object: ActivityMessage) => {
    var response = await mainApi.postActivityMessage(object)
    dispatch(fetch_activities(paginationActivities, fetchLimit))
    dispatch(fetch_activities_queued(paginationActivitiesQueued, fetchLimit))
    setDialogOpen(false)
  }

  const handleOnUpdate = async (_id: string, object: ActivityMessage) => {
    var response = await mainApi.patchActivityMessage(_id, object)
    dispatch(fetch_activities(paginationActivities, fetchLimit))
    dispatch(fetch_activities_queued(paginationActivitiesQueued, fetchLimit))
    setDialogOpen(false)
  }

  const handleOnClickNext = async () => {
    if (currentStep === 0) {
      var validate = true
      if (activity.imageUrl.length > 0 || activity.actions.length > 0 && activity.title) {
        if (activity.imageUrl.length < 4)
          validate = false
        if (!activity.text)
          validate = false

        if (validate === true)
          setCurrentStep(1)
      }
      else if (activity.text !== null && activity.text !== '' && activity.title)
        setCurrentStep(1)
      else
        validate = false
    }
    else if (currentStep === 1) {
      var error = false

      var receiver_type = data.receiver_type
      var receiver = data.receiver
      var schedule_time = null
      var newActivity = null
      if (isScheduled) {
        if (data.scheduledDate && data.scheduledTime)
          schedule_time = moment(`${data.scheduledDate} ${data.scheduledTime}`, 'YYYY-MM-DD HH:mm').format("YYYY-MM-DD HH:MM:ss")
        else
          error = true
      }

      if (!data.receiver_type)
        error = true
      if (data.receiver_type === 'groups') {
        if (!data.receiver)
          error = true
        else {
          var texts = []
          for (const iterator of data.receiver) {
            const response = await mainApi.fetchGroups(createRequestFilter({ displayName: iterator }), 1)
            if (response.success === true) {
              texts.push(response.data[0].id)
            }
          }
          receiver = texts.toString()
        }
      }
      if (data.receiver_type === 'users') {
        if (!data.receiver)
          error = true
        else {
          var texts = []
          for (const iterator of data.receiver) {
            const response = await mainApi.fetchUsers(createRequestFilter({ mail: iterator }), 1)
            if (response.success === true) {
              texts.push(response.data[0].id)
            }
          }
          receiver = texts.toString()
        }
      }
      if (activity.title && activity.text && activity.imageUrl.length > 0) {
        newActivity = activity
      }
      else {
        newActivity = activity.text
      }
      console.log(receiver)
      if (error === false) {
        if (data._id) {
          handleOnUpdate(data._id, {
            author: context.userPrincipalName || "<none>",
            title: activity.title,
            draft: 0,
            receiver_type: receiver_type,
            receiver: receiver,
            schedule_time: schedule_time,
            activity: newActivity
          })
        }
        else {
          handleOnSubmit({
            author: context.userPrincipalName || "<none>",
            title: activity.title,
            draft: 0,
            receiver_type: receiver_type,
            receiver: receiver,
            schedule_time: schedule_time,
            activity: newActivity
          })
        }
      }
    }
  };

  const handleOnReceiverTypeChange = (_: any, event: any) => {
    setData(prevData => ({
      ...prevData,
      receiver_type: event.value
    }))
  }

  const handleOnReceiverChange = (_: any, event: any) => {
    const { value } = event
    setData(prevData => ({
      ...prevData,
      receiver: value
    }))
  }

  const handleOnChange = (event: any) => {
    const { id, value } = event.target
    setActivity(prevData => ({
      ...prevData,
      [id]: value
    }))
  }

  const handleOnChangeData = (event: any) => {
    const { id, value } = event.target
    setData(prevData => ({
      ...prevData,
      [id]: value
    }))
  }

  const handleOnChangeActionButton = (event: any, index: any) => {
    const { id, value } = event.target
    var actions = activity.actions
    var obj = activity.actions[index]
    obj = { ...obj, [id]: value }
    actions[index] = obj
    setActivity(prevData => ({
      ...prevData,
      actions: actions
    }))
  }

  const handleOnClickAddButton = () => {
    var newArray = activity.actions
    newArray.push({
      title: null,
      value: null
    })
    setActivity(prevData => ({
      ...prevData,
      actions: newArray
    }))
  }

  const handleOnClickRemoveButton = (index: any) => {
    var newArray = activity.actions
    newArray.splice(index, 1)
    setActivity(prevData => ({
      ...prevData,
      actions: newArray
    }))
  }

  const handleOnClickDeleteItem = async (_id: string) => {
    const result = await mainApi.removeActivityMessage(_id)
    if (result.success === true) {
      dispatch(fetch_activities(paginationActivities, fetchLimit))
      dispatch(fetch_activities_queued(paginationActivitiesQueued, fetchLimit))
    }
  }

  const handleOnClickCopyItem = async (_id: string) => {
    const result = await mainApi.fetchActivityMessage(_id)
    if (result.success === true) {
      handleOnSubmit({
        author: context.userPrincipalName || "<none>",
        title: result.activity.title,
        draft: 1,
        receiver_type: result.activity.receiver_type,
        receiver: result.activity.receiver,
        schedule_time: null,
        activity: result.activity.activity
      })
    }
  }

  const handleOnClickOpenItem = async (_id: string) => {
    const result = await mainApi.fetchActivityMessage(_id)
    if (result.success === true) {
      if (typeof result.activity.activity === 'object' && result.activity.activity !== null) {
        setActivity(result.activity.activity)
      }
      else {
        setActivity({
          title: result.activity.title,
          imageUrl: '',
          text: result.activity.activity,
          actions: [] as any[]
        })
      }

      setData({
        _id: result.activity._id,
        draft: result.activity.draft,
        receiver_type: result.activity.receiver_type,
        receiver: result.activity.receiver,
        scheduledDate: null,
        scheduledTime: null
      })

      if (result.activity.queued === 1)
        setPreviewOpen(true)
      else {
        setDialogOpen(true)
      }

    }
  }

  return (
    <Flex column>
      <Flex gap="gap.smaller" hAlign='end'>
        <Button icon={<AddIcon />} content={t("button-create")} primary onClick={handleOnClickNewAnnouncement} />
      </Flex>
      <ActivityTable
        headline={t('headline-scheduleActivity')}
        activity={activities}
        rowsCount={activitiesCount}
        rowsLimit={fetchLimit}
        onClickNext={handleOnFetchNextActivities}
        onClickPrev={handleOnFetchPrevActivities}
        onClickOpenItem={handleOnClickOpenItem}
        onClickDeleteItem={handleOnClickDeleteItem}
        onClickCopyItem={handleOnClickCopyItem}
      />
      <ActivityTable
        headline={t('headline-sentActivity')}
        activity={activitiesQueued}
        rowsCount={activitiesQueuedCount}
        rowsLimit={fetchLimit}
        onClickNext={handleOnFetchNextActivitiesQueued}
        onClickPrev={handleOnFetchPrevActivitiesQueued}
        onClickOpenItem={handleOnClickOpenItem}
        onClickDeleteItem={handleOnClickDeleteItem}
        onClickCopyItem={handleOnClickCopyItem}
      />
      <CustomDialog
        open={dialogOpen}
        header={t('content-newAnnouncement')}
        cancel={t("button-cancel")}
        onCancel={setDialogOpen}
        confirm={currentStep === 1 ? t("button-confirm") : t("button-next")}
        onConfirm={handleOnClickNext}
        children={
          <Flex gap="gap.small">
            <Flex.Item size="size.half">
              {currentStep === 1 ? renderSubmitForm(t, data, handleOnReceiverTypeChange, getItems, isScheduled, setIsScheduled, handleOnChangeData) : renderActivityForm(t, handleOnChange, handleOnClickAddButton, activity, handleOnChangeActionButton, handleOnClickRemoveButton)}
            </Flex.Item>
            <Flex.Item size="size.half">
              {renderPreviewCard(activity)}
            </Flex.Item>
          </Flex>
        }
        style={{ width: '80%' }}
      />
      <CustomDialog
        open={previewOpen}
        header={t('content-announcement')}
        onCancel={setPreviewOpen}
        confirm={t("button-close")}
        onConfirm={() => setPreviewOpen(false)}
        children={
          renderPreviewCard(activity)
        }
      />
    </Flex>
  );
}

export default Dashboard

function renderSubmitForm(t: any, data: { receiver_type: string; receiver: null; scheduledDate: null; scheduledTime: null; }, handleOnReceiverTypeChange: (_: any, event: any) => void, getItems: () => ({ name: string; key: string; label: string; value: string; children?: undefined; } | { name: string; key: string; label: string; value: string; children: (Component: any, { ...props }: { [x: string]: any; }) => JSX.Element; })[], isScheduled: boolean, setIsScheduled: React.Dispatch<React.SetStateAction<boolean>>, handleOnChangeData: (event: any) => void) {
  return <Form>
    <Header as="h3" content={t("headline-chooseRecipient")} />
    <RadioGroup
      vertical
      defaultCheckedValue={data.receiver_type}
      checkedValue={data.receiver_type}
      onCheckedValueChange={handleOnReceiverTypeChange}
      items={getItems()} />
    <Header as="h3" content={t("headline-scheduledSend")} />
    <Checkbox checked={isScheduled} label={t("label-scheduledSend")} toggle onChange={() => setIsScheduled(!isScheduled)} />
    <Flex gap="gap.small">
      <FormInput
        label={t("label-date")}
        name="scheduledDate"
        id="scheduledDate"
        type="date"
        fluid
        onChange={handleOnChangeData}
        disabled={!isScheduled}
        showSuccessIndicator={false} />
      <FormInput
        label={t("label-time")}
        name="scheduledTime"
        id="scheduledTime"
        type="time"
        fluid
        onChange={handleOnChangeData}
        disabled={!isScheduled}
        showSuccessIndicator={false} />
    </Flex>
  </Form>;
}

function renderActivityForm(t: any, handleOnChange: (event: any) => void, handleOnClickAddButton: () => void, activity: { title: null; imageUrl: string; text: null; actions: any[]; }, handleOnChangeActionButton: (event: any, index: any) => void, handleOnClickRemoveButton: (index: any) => void) {
  return <Form>
    <FormInput
      label={`${t("label-headline")}*`}
      name="title"
      id="title"
      defaultValue={activity.title}
      fluid
      style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
      showSuccessIndicator={false}
      onChange={handleOnChange} />
    <FormInput
      label={t("label-imageUrl")}
      name="imageUrl"
      id="imageUrl"
      defaultValue={activity.imageUrl}
      fluid
      style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
      showSuccessIndicator={false}
      onChange={handleOnChange} />
    <label style={{ marginBottom: "0.25rem" }} className='ui-form__label bd hp hq'>{t("label-summary")}*</label>
    <ControlledEditor
      id="text"
      name="text"
      htmlContent={activity.text}
      onChange={handleOnChange}
    />
    <Flex gap="gap.small" vAlign="center" space="between">
      <label className='ui-form__label bd hp hq'>{t("label-buttons")}</label>
      <Flex gap="gap.small">
        <Button circular icon={<Icon iconName="Add" />} primary title="Add" onClick={handleOnClickAddButton} />
      </Flex>
    </Flex>
    {activity.actions.map((element, index) => {
      return (
        <Flex gap="gap.small" vAlign="end">
          <FormInput
            label={t("label-title")}
            defaultValue={element.title}
            name="title"
            id="title"
            fluid
            showSuccessIndicator={false}
            onChange={(e) => handleOnChangeActionButton(e, index)} />
          <FormInput
            label={t("label-value")}
            defaultValue={element.value}
            name="value"
            id="value"
            fluid
            showSuccessIndicator={false}
            onChange={(e) => handleOnChangeActionButton(e, index)} />
          <FormButton
            circular
            icon={<Icon iconName="Delete" />}
            tinted title="Remove"
            onClick={() => handleOnClickRemoveButton(index)} />
        </Flex>
      );
    })}
  </Form>;
}

function renderPreviewCard(activity: { title: null; imageUrl: string; text: null; actions: any[]; }) {

  const gridStyles = {
    gridColumnGap: '10px',
    gridRowGap: '10px',
  }

  return (
    <Segment color="brand">
      <Flex gap="gap.small" vAlign="center">
        <Avatar
          icon={<PersonIcon />}
          label="Announcement"
          name="Announcement"
          status={{
            color: 'green',
            title: 'Available',
          }} />
        <Header as="h4" content="Announcement" />
      </Flex>
      <Flex gap="gap.small">
        <Flex column>
          <Header as="h2" content={activity.title} style={{ wordWrap: 'break-word', wordBreak: 'break-all' }} />
        </Flex>
      </Flex>
      <Flex column gap="gap.small">
        <Image src={activity.imageUrl} style={{ objectFit: 'cover', maxWidth: "100%" }} />
      </Flex>
      <Flex column gap="gap.small">
        <div dangerouslySetInnerHTML={{ __html: activity.text }} style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}></div>
      </Flex>
      <Flex gap="gap.small">
        <Grid styles={gridStyles}>
          {activity.actions.map(element => {
            return (
              <Button content={element.title} tinted />
            );
          })}
        </Grid>
      </Flex>
    </Segment>
  );
}