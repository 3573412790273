import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as microsoftTeams from "@microsoft/teams-js";
import i18n from './i18n';
import Router from './Router';
import { set_context_object } from './store/actionCreators';

function App() {
  const dispatch = useDispatch()
  const [appContext, setAppContext] = useState<microsoftTeams.Context>();

  var authTokenRequest = {
    successCallback: function(result) { console.log("Success: " + result); },
    failureCallback: function(error) { console.log("Error getting token: " + error); }
  };
  //microsoftTeams.authentication.getAuthToken(authTokenRequest);

  useEffect(() => {
    microsoftTeams.getContext((context) => {
      setAppContext(context);
      
      microsoftTeams.appInitialization.notifySuccess();
      try{
        let language = context.locale.split(/-|_/)[0]
        i18n.changeLanguage(language)
      }
      catch {
        i18n.changeLanguage('en')
      }
    });
  },[])

  useEffect(() => {
    dispatch(set_context_object(appContext));
    // eslint-disable-next-line
  }, [appContext])
  
  return (
    < Router />
  );
}

export default App;
