import * as React from 'react';
import { Dialog } from "@fluentui/react-northstar"
import { Icon } from '@fluentui/react/lib/Icon';

type Props = {
  open?: any;
  header?: React.ReactNode | string,
  children?: React.ReactNode;
  confirm?: string,
  cancel?: string,
  style?: any,
  onCancel?: any,
  onConfirm?: any,
}

const CustomDialog = ({ open, header, children, confirm, cancel, onCancel, onConfirm, style }: Props) => {
  return (
    <Dialog
      styles={{...style}}
      header={header}
      open={open}
      content={children}
      cancelButton={cancel}
      confirmButton={confirm}
      onCancel={() => onCancel(!open)}
      onConfirm={onConfirm}
      headerAction={{ icon: <Icon iconName="ChromeClose" />, title: 'Close', onClick: () => onCancel(!open) }}
    />
  )
}

export default CustomDialog