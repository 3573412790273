import React from 'react';
import { useTranslation } from "react-i18next";
import { Grid, Header, Image } from '@fluentui/react-northstar';

import HeaderImage from '../assets/images/announcementApp.png'

export const Welcome: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const style = {
    container: {
      margin: '2.3rem'
    },
  }

  return (
    <main style={{...style.container}}>
      <Grid columns={1}>
        <Header
        as='h1'
        align='center'
        content={t('headline-announcementApp')}
        description={{
          content:t('content-announcementApp'),
          as: 'span',
        }}
        />
        <Image fluid src={HeaderImage} />
      </Grid>
    </main>
  );
}

export default Welcome