import React from "react";
import { Flex, Image, Header } from "@fluentui/react-northstar"

import logo from "../assets/images/announcement.png"
import { useTranslation } from "react-i18next";

const style = {
  container: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  section: {
    paddingTop: '3.9rem',
    paddingBottom: '3.9rem'
  }
}

type Props = {
  title?: string;
  children?: React.ReactNode;
}

const Layout = ({ title, children }: Props) => {
  const {t} = useTranslation()
  return (
    <Flex 
      column 
      styles={style.container}
    >
      <Flex gap="gap.small" styles={style.section}>
        <Image
          fluid
          src={logo}
          styles={{maxWidth: '65px', maxHeight: '65px', marginTop: 'auto', marginBottom:'auto'}}
        />
        <Header as="h1" styles={{marginTop: 'auto', marginBottom:'auto'}} content={t('headline-announcement')} description={{
            content: t('subline-announcement', {company: window.__RUNTIME_CONFIG__.REACT_APP_COMPANY}),
            as: 'span',
          }}
        />
      </Flex>
      <main>
        {children}
      </main>
    </Flex>
  );
};

export default Layout