import * as React from 'react';
import { useEffect, useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const htmlToDraftBlocks = (html) => {
  const blocksFromHtml = htmlToDraft(html)
  const { contentBlocks, entityMap } = blocksFromHtml
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
  return EditorState.createWithContent(contentState)
 }

type Props = {
  id?: string;
  name?: string;
  htmlContent?: any;
  onChange?: any;
}

const ControlledEditor = ({id, name, htmlContent, onChange}: Props) => {

  const [ editorState, setEditorState ] = useState(
    () => EditorState.createEmpty()
  );
  const [ editorStyle, setEditorStyle ] = useState({
    borderBottomStyle: 'solid', 
    borderBottomColor: 'transparent', 
    borderBottomWidth: '0px', 
    borderBottomRadius: 4
  });

  useEffect(() => {
    if(htmlContent)
      setEditorState(htmlToDraftBlocks(htmlContent));
  }, []);

  const handleOnEditorChange = (editorState: EditorState) => {
    setEditorState(editorState)
    onChange({target: {id: id, value: draftToHtml(convertToRaw(editorState.getCurrentContent()))}})
  }

  return(
    <Editor
      id={id} 
      name={name}
      toolbarStyle={{borderRadius: 4, paddingTop: '0.3125rem', paddingLeft: '0.75rem', paddingRight: '2.1875rem', paddingBottom: '0.3125rem', backgroundColor:'rgb(243, 242, 241)', justifyContent: 'flex-start'}}
      editorStyle={{...editorStyle, borderRightRadius: 4, borderLeftRadius: 4, borderTopRadius: 4, paddingTop: '0.3125rem', paddingLeft: '0.75rem', paddingRight: '2.1875rem', paddingBottom: '0.3125rem', backgroundColor:'rgb(243, 242, 241)', minHeight: 180, height: '100%', width: '100%', display: 'flex', color: 'rgb(17, 16, 15)', wordWrap: 'break-word', wordBreak: 'break-all'}}
      toolbar={{
        options: ['inline', 'list', 'textAlign', 'link'],
        inline: { inDropdown: true, options: ['bold', 'italic', 'underline'] },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
      }}
      editorState={editorState}
      onEditorStateChange={handleOnEditorChange}
      onFocus={() => {setEditorStyle({borderBottomStyle: 'solid', borderBottomColor: 'rgb(91, 95, 199)', borderBottomWidth: '0.125rem', borderBottomRadius: 0})}}
      onBlur={() => {setEditorStyle({borderBottomStyle: 'solid', borderBottomColor: 'transparent', borderBottomWidth: '0px', borderBottomRadius: 4})}}
    />
  )
}

export default ControlledEditor