import React from 'react';
import { useTranslation } from "react-i18next";

export const TermsOfUse: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1>Terms of Use</h1>
    </div>
  );
}

export default TermsOfUse