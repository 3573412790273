import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { About, Privacy, TermsOfUse, Welcome } from './pages';
import { Dashboard } from './pages/static';
import Layout from './layout'

const Router = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/about" element={<About />} />
        <Route path="/termsofuse" element={<TermsOfUse />} />
        <Route path="/" element={<Layout><Dashboard /></Layout>} />
        <Route
          path="*"
          element={
            <Welcome />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;