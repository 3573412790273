import HttpClient from './http-client'

import { Groups, ActivityMessage } from './types';

export default class MainApi extends HttpClient {
  private static classInstance?: MainApi;

  private constructor() {
    super(`${window.__RUNTIME_CONFIG__.REACT_APP_API_URL}/api/${window.__RUNTIME_CONFIG__.REACT_APP_API_VERSION}/client`);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new MainApi();
    }
    return this.classInstance;
  }

  private getFilter(filter) {
    var result = ""
    if (filter) {
      for (const key in filter) {
        result += `&filter=AND(${key}:${filter[key]})`
      }
    }
    return result
  }

  public fetchGroups = (filter, limit) => this.instance.get<Response>(`/receivers/groups?&limit=${limit}${filter}`);
  public fetchUsers = (filter, limit) => this.instance.get<Response>(`/receivers/users?&limit=${limit}${filter}`);
  public fetchActivityMessages = (filter, skip, limit) => this.instance.get<Response>(`/messages?skip=${skip}&limit=${limit}${this.getFilter(filter)}`)
  public fetchActivityMessage = (id) => this.instance.get<Response>(`/messages/${id}`)
  public removeActivityMessage = (id) => this.instance.delete<Response>(`/messages/${id}`)
  public patchActivityMessage = (id, body: ActivityMessage) => this.instance.patch(`/messages/${id}`, body);
  public fetchActivityMessageResults = (id) => this.instance.get<Response>(`/messages/${id}/results`)
  public postActivityMessage = (body: ActivityMessage) => this.instance.post('/messages', body);
}