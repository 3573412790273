import * as React from 'react';
import { Segment, Header, Flex, Button, Skeleton, Text, Tooltip, Menu, Divider } from "@fluentui/react-northstar";
import { ShiftActivityIcon, AcceptIcon, ErrorIcon, ArrowLeftIcon, ArrowRightIcon, SurveyIcon, OpenOutsideIcon, RedoIcon, CloseIcon } from '@fluentui/react-icons-northstar';
import { useTranslation } from 'react-i18next';
import MainApi from '../../utils/api/main';
import moment from 'moment';

const ActivityTable = ({ activity, headline, description, rowsCount = 0, rowsLimit = 10, onClickPrev, onClickNext, onClickDeleteItem, onClickCopyItem, onClickOpenItem }: any) => {
  const {t} = useTranslation()
  const style = {
    section: {
      paddingTop: '3.9rem',
      paddingBottom: '3.9rem'
    },
    segment: {
      background: 'rgba(221, 221, 221, 0.533)'
    },
    table: {
      padding: 0
    }
  }

  return (
    <div style={style.section} key={`activity-table-${headline}`}>
      <Segment style={{...style.segment}}>
        <Flex space="between">
          <Header
            as="h2"
            content={headline}
            description={description && {
              content: {description},
              as: 'span',
            }}
          />
          {(rowsCount > rowsLimit) && <Flex gap="gap.small" vAlign='center'>
            <Button circular icon={<ArrowLeftIcon />} onClick={onClickPrev} />
            <Button circular icon={<ArrowRightIcon />} onClick={onClickNext} />
          </Flex>}
        </Flex>
      </Segment>
      <Segment style={{...style.table}}>
        {activity && activity.map((element, key) => (
          <ActivityItem id={element._id} activity={element} clickDeleteItem={onClickDeleteItem} clickCopyItem={onClickCopyItem} clickOpenItem={onClickOpenItem}/>
        ))}
      </Segment>
    </div>
  )
}

const ActivityItem = ({id, activity, clickDeleteItem, clickCopyItem, clickOpenItem}) => {
  const {t} = useTranslation()
  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState()
  const mainApi = MainApi.getInstance()

  const style = {
    pd: {
      padding: 15
    },
    mb_0: {
      marginBottom: 0
    },
    mt_0: {
      marginTop: 0
    }
  }

  const prevActivity = usePrevious({activity});

  React.useEffect(()=>{
    const loadData =async (_id:string) => {
      if(!prevActivity)
        setLoading(true)
      var result = await mainApi.fetchActivityMessage(_id)
      if(result.success === true) {
        setData(result.activity)
        setLoading(false)
      }
    }
    if(activity._id)
      loadData(activity._id)
  },[activity])

  function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const boxMouseOverHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    const box: HTMLDivElement = event.currentTarget;
    box.style.backgroundColor = "#f5f5f5";
  };

  const boxMouseOutHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    const box: HTMLDivElement = event.currentTarget;
    box.style.backgroundColor = "";
  };

  if(!data || loading)
    return(
      <Flex column gap="gap.medium" key={activity._id} style={{...style.pd}}>
        <Skeleton animation="pulse">
          <Skeleton.Line />
          <Skeleton.Line height='2rem' width="70%" />
          <Skeleton.Line width="50%" />
        </Skeleton>
      </Flex>
    )

  return(
    <Flex 
      column 
      gap="gap.medium" 
      key={activity._id} 
      style={{...style.pd}}
      onMouseOver={boxMouseOverHandler}
      onMouseOut={boxMouseOutHandler}
    >
      <Flex space="between" hAlign='start' vAlign='center' style={{...style.mb_0}}>
        <Header
          as="h3"
          style={{...style.mt_0}}
          content={`${t('label-author')}: ${data.author}`}
          description={{
            content: `${data.title}`,
            as: 'span',
          }}
        />
        <Flex column gap="gap.medium" >
          {data.queued === 1 && 
            <Flex gap="gap.small">
              <Text><Tooltip content={t('label-sent')} trigger={<AcceptIcon xSpacing="after" styles={{color:'#107c10'}} />} />{data.details.sent || '0'}</Text>
              <Text><Tooltip content={t('label-error')} trigger={<ErrorIcon xSpacing="after" />} />{data.details.error || '0'}</Text>
              <Text><Tooltip content={t('label-wait')} trigger={<ShiftActivityIcon xSpacing="after" styles={{color:'#0078d4'}} />} />{data.details.wait || '0'}</Text>
            </Flex>
          }
          <Flex gap="gap.small" style={{justifyContent: 'center'}}>
            <Menu iconOnly defaultActiveIndex={0}>
              <Tooltip content={t('label-openOutside')}
                trigger={
                  <OpenOutsideIcon outline size="large" onClick={()=>clickOpenItem(activity._id)}/>
                }
              />
              <Divider vertical style={{marginLeft: 10, marginRight: 10}} />
              <Tooltip content={t('label-redo')}
                trigger={
                  <RedoIcon outline size="large" onClick={()=>clickCopyItem(activity._id)}/>
                }
              />
              <Divider vertical style={{marginLeft: 10, marginRight: 10}} />
              <Tooltip content={t('label-delete')}
                trigger={
                  <CloseIcon outline size="large" onClick={()=>clickDeleteItem(activity._id)}/>
                }
              />
            </Menu>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap="gap.small" hAlign='start'>
        {data && <Text><SurveyIcon xSpacing="after" styles={{color:'#201f1e'}} />{`${t('label-createTime')} ${moment(data.create_time).format("DD-MM-YYYY HH:mm")}`}</Text>}
        {data.schedule_time && <Text><SurveyIcon xSpacing="after" styles={{color:'#0078d4'}} />{`${t('label-scheduleTime')} ${moment(data.schedule_time).format("DD-MM-YYYY HH:mm")}`}</Text>}
      </Flex>
    </Flex>
  )
}

export default ActivityTable